// NOTE: We only support `Meter` & `Foot` in the UI.
export enum Unit {
  Meter = 'M',
  Foot = 'FT',
  // Millimeter = 'MM',
  // Centimeter = 'CM',
  // Kilometer = 'KM',
  // Inch = 'IN',
  // Yard = 'YD',
  // Mile = 'MIL',
}

export enum MLEnum {
  'veinnet' = 'Vein Net',
  'roqenet' = 'RoQE Net',
  'percentageReport' = 'Dominant Color',
  'percentageReportLight' = 'percentageReportLight',
  'veinFrequency' = 'Vein Frequency',
  'fractureFrequency' = 'Fracture Frequency',
  'rqd' = 'RQD',
  'textureReport' = 'Texture Report',
  'fcnet' = 'FC-Net',
  'veins' = 'Veins',
}

/**
 * The type of log used to determine the type of chart available
 */
export enum LogType {
  qual = 'qualitative',
  quant = 'quantitative',
  default = 'qualitative',
}

/**
 * Required fields for importing logs from file
 */
export enum LogImportFields {
  drillholeId = 'drillholeId',
  fromDepth = 'fromDepth',
  toDepth = 'toDepth',
}

/**
 * Required fields for Logs API Data Item
 */
export enum LogsApiSystemColumns {
  drillholeId = 'drillhole_id',
  fromDepth = 'depth_from',
  toDepth = 'depth_to',
  createdAt = 'created_at',
  createdBy = 'created_by',
  updatedAt = 'updated_at',
  updatedBy = 'updated_by',
  logId = 'log_id',
  _id = '_id',
  pointDataOnly = 'pointDataOnly',
}

export enum KnownFileNamePatterns {
  als = '^(.*\\/)*(?<dhid>.*?)_(?<boxNo>.*?)_(?<fromDepth>.*)-(?<toDepth>.*)\\.(jpeg|JPEG|jpg|JPG|png|PNG)$',
  terracore = '^(.*\\/)*RGB_Image__RGB__(?<dhid>.*?)_(?<boxNo>.*)@(?<fromDepth>.*?)_(?<toDepth>.*)\\.(jpeg|JPEG|jpg|JPG|png|PNG)$',
  imago = '^(.*\\/)*(?<dhid>.*)_(?<fromDepth>\\d+\\.?\\d+)_(?<toDepth>\\d+\\.?\\d+)_\\w+\\.(jpeg|JPEG|jpg|JPG|png|PNG)$',
  default = '^(.*\\/)*(?<dhid>.*?)_(?<boxNo>.*?)_(?<fromDepth>.*)-(?<toDepth>.*)\\.(jpeg|JPEG|jpg|JPG|png|PNG)$',
}

export enum ImageUploadColumnHeaders {
  dhid = 'dhid',
  box = 'boxNo',
  fromDepth = 'fromDepth',
  toDepth = 'toDepth',
  status = 'error',
  imageType = 'imageType',
  imageTypeKey = 'imageTypeKey',
}

export enum ImageUploadStatus {
  error = 'error',
  todo = 'todo',
}

export enum DataGridCellClass {
  invalid = 'invalid',
  warning = 'warning',
  default = '',
}

export enum ValidStatus {
  todo = 'todo',
  drillholeProgress = 'drillholeProgress',
  drillholeDone = 'drillholeDone',
  uploading = 'uploading',
  done = 'done',
  error = 'error',
}

export enum RegisteredImageType {
  core = 'core',
  atv = 'atv',
  otv = 'otv',
  wet = 'wet',
  dry = 'dry',
  hs = 'hs',
  default = 'core',
}

// degrees to rotate clockwise
export enum Rotation {
  deg0 = 0, // no rotation
  deg90 = 90,
  deg180 = 180,
  deg270 = 270,
}

export enum ChartType {
  categorical = 'categorical',
  numerical = 'numerical',
  image = 'image',
  polygon = 'polygon',
}

export enum CleanImageCoreType {
  multiple = 'multiple',
  single = 'single',
}

export enum JobStatus {
  done = 'done',
  inProgress = 'in-progress',
  requested = 'requested',
  todo = 'todo',
}
